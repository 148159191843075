// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Dakota Soares
 * @version 1.1
 * @description Login Page CSS
 */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    text-align: center;
  }
  
  .login-container img {
    width: 70%;
    max-width: 300px;
  }
  
  .login-container form {
    width: 100%;
    max-width: 400px;
  }
  
  .login-container label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .login-container input,
  .login-container button {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  .login-container .error {
    color: red;
    margin-bottom: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/loginPage/loginPage.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;;IAEE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,UAAU;IACV,mBAAmB;EACrB","sourcesContent":["/**\n * @author Dakota Soares\n * @version 1.1\n * @description Login Page CSS\n */\n\n.login-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 80vh;\n    text-align: center;\n  }\n  \n  .login-container img {\n    width: 70%;\n    max-width: 300px;\n  }\n  \n  .login-container form {\n    width: 100%;\n    max-width: 400px;\n  }\n  \n  .login-container label {\n    display: block;\n    margin-bottom: 5px;\n    text-align: left;\n  }\n  \n  .login-container input,\n  .login-container button {\n    width: 100%;\n    padding: 8px;\n    margin-top: 5px;\n    margin-bottom: 15px;\n    box-sizing: border-box;\n  }\n  \n  .login-container .error {\n    color: red;\n    margin-bottom: 15px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
